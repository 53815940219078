import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AppPageProps } from '../types'
import { DistillerPageEnQuery } from '../../types/graphql-types'
import { DistillerText } from '../components/DistillerText'
import { Layout } from '../components/Layout'
import { LoadingContext } from '../contexts/LoadingContext'
import { OpticalImage } from '../components/OpticalImage'
import { SectionList } from '../components/SectionList'

export const query = graphql`
  query DistillerPageEn {
    heroMobile: file(relativePath: { eq: "images/distiller-hero@mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(relativePath: { eq: "images/distiller-hero@tablet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface DistillerPageProps extends AppPageProps {
  data: DistillerPageEnQuery
}

const DistillerPage: React.FC<DistillerPageProps> = ({
  pathContext: { langKey },
  data: { heroMobile, heroTablet },
}) => (
  <Layout langKey={langKey} pageTitle="Distiller" titleLabel="Distiller">
    <SectionList>
      <LoadingContext.Provider value="eager">
        <OpticalImage
          fluidMobile={heroMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={heroTablet?.childImageSharp?.fluid as FluidObject}
          sizes="100vw"
        />
      </LoadingContext.Provider>
      <DistillerText>
        <dl>
          <dt>President</dt>
          <dd>Shinsaku Kuroki</dd>
          <dt>Established on</dt>
          <dd>1998</dd>
          <dt>Tel</dt>
          <dd>
            <a href="tel:+81983223973">+81983223973</a>
          </dd>
          <dt>Email</dt>
          <dd>
            <a href="mailto:info@osuzuyama.co.jp">info@osuzuyama.co.jp</a>
          </dd>
          <dt>Location</dt>
          <dd>
            <a
              href="https://goo.gl/maps/mSZHJSQGje82"
              target="_blank"
              rel="noopener noreferrer"
            >
              656-17, Kuratani, Ishikawauchi, Kijo-cho, Koyu-gun, Miyazaki,
              Japan
              <br />
              〒884-0104
            </a>
          </dd>
        </dl>
      </DistillerText>
    </SectionList>
  </Layout>
)

export default DistillerPage
